import {computed, ref} from "vue";
import axios from "axios";
import {v4 as uuid} from "uuid";
import {useSessionStorage} from "@vueuse/core";
import {initiateGoogleLogin,initiateLinkedInLogin} from "@/services/auth.service";

const ENDPOINTS = {
    LOGIN: "/auth/login",
    LOGOUT: "/auth/logout",
    GET_USER: "/auth/login/"
};

// LinkedIn OAuth Configuration
const LINKEDIN_CONFIG = {
    clientId: import.meta.env.LINKEDIN_CLIENT_ID, // Replace with your LinkedIn App Client ID
    redirectUri: window.location.origin + '/auth/linkedin/callback',
    scope: "openid profile email",
    state: uuid(), // Security state parameter
};

export function useApi() {
    const isSubmitting = ref(false);
    const session = useSessionStorage('linkedin_oauth_state', LINKEDIN_CONFIG.state);

    const invoke = async (endpoint, method = 'GET', body = {}) => {
        isSubmitting.value = true;

        try {
            const headers = {};
            const csrfToken = document.querySelector('meta[name="csrf-token"]');
            if (csrfToken) {
                headers['X-CSRF-Token'] = csrfToken.content;
            }
            return await axios({method: method, url: endpoint, data: body, headers: headers});
        } catch (err) {
            console.error(err.response);
            return err.response;
        } finally {
            isSubmitting.value = false;
        }
    };

    return {
        ENDPOINTS,
        isSubmitting: computed(() => isSubmitting.value),
        invoke,
        googleLogin: () => {
            initiateGoogleLogin();
            // const handleCallback = async (response) => {
            //     try {
            //         // Exchange the code for tokens
            //         const {code} = response;

            //         // Get tokens from Google's token endpoint
            //         const tokenResponse = await fetch('https://oauth2.googleapis.com/token', {
            //             method: 'POST',
            //             headers: {
            //                 'Content-Type': 'application/x-www-form-urlencoded',
            //             },
            //             body: new URLSearchParams({
            //                 code,
            //                 client_id: import.meta.env.GOOGLE_CLIENT_ID,
            //                 client_secret: import.meta.env.GOOGLE_CLIENT_SECRET,
            //                 redirect_uri: "http://localhost:3000/auth/google/callback",
            //                 grant_type: 'authorization_code',
            //             }),
            //         });

            //         const {access_token} = await tokenResponse.json();

            //         // Get user info using the access token
            //         const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
            //             headers: {
            //                 'Authorization': `Bearer ${access_token}`,
            //             },
            //         });

            //         const userData = await userInfo.json();
            //         console.log('User Data:', userData);

            //         // Send userData to your backend
            //         // const backendResponse = await fetch('your-backend-url/auth/google', {
            //         //     method: 'POST',
            //         //     headers: {
            //         //         'Content-Type': 'application/json',
            //         //     },
            //         //     body: JSON.stringify(userData)
            //         // });

            //     } catch (error) {
            //         console.error('Error in callback:', error);
            //     }
            // };

            // const launchModal = () => {
            //     // Initialize after script loads
            //     google.accounts.oauth2.initCodeClient({
            //         client_id: import.meta.env.GOOGLE_CLIENT_ID,
            //         scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
            //         callback: handleCallback,
            //         ux_mode: 'popup',
            //     }).requestCode();  // This will show the Google popup
            // };

            // // Load Google Sign-In
            // if (!document.getElementById("google-js-sdk")) {
            //     const script = document.createElement('script');
            //     script.src = 'https://accounts.google.com/gsi/client';
            //     script.id = "google-js-sdk";
            //     script.async = true;
            //     script.defer = true;
            //     script.onload = launchModal;
            //     document.head.appendChild(script);
            // } else {
            //     launchModal();
            // }
        },
        linkedinLogin: async () => {
            initiateLinkedInLogin();
            // try {
            //     // Store state for validation
            //     sessionStorage.setItem('linkedin_oauth_state', LINKEDIN_CONFIG.state);

            //     // Construct LinkedIn OAuth URL
            //     const authUrl = new URL('https://www.linkedin.com/oauth/v2/authorization');
            //     const params = {
            //         response_type: 'code',
            //         client_id: LINKEDIN_CONFIG.clientId,
            //         redirect_uri: LINKEDIN_CONFIG.redirectUri,
            //         // state: LINKEDIN_CONFIG.state,
            //         scope: LINKEDIN_CONFIG.scope
            //     };

            //     Object.keys(params).forEach(key =>
            //         authUrl.searchParams.append(key, params[key])
            //     );

            //     // Open popup
            //     const popup = window.open(
            //         authUrl.toString(),
            //         'LinkedIn Login',
            //         'width=600,height=600,left=100,top=100'
            //     );

            //     // Promise to handle the popup response
            //     const authResponse = await new Promise((resolve, reject) => {
            //         // Check if popup was blocked
            //         if (!popup) {
            //             reject(new Error('Popup was blocked. Please enable popups for this site.'));
            //             return;
            //         }

            //         // Handle popup closure
            //         const popupTimer = setInterval(() => {
            //             if (popup.closed) {
            //                 clearInterval(popupTimer);
            //                 reject(new Error('Authentication cancelled'));
            //             }
            //         }, 1000);

            //         // Handle message from popup
            //         const handleMessage = async (event) => {
            //             // Verify origin
            //             if (event.origin !== window.location.origin) return;

            //             try {
            //                 const {code, state, error} = event.data;

            //                 // Clean up
            //                 clearInterval(popupTimer);
            //                 window.removeEventListener('message', handleMessage);

            //                 // Verify state
            //                 const storedState = sessionStorage.getItem('linkedin_oauth_state');
            //                 sessionStorage.removeItem('linkedin_oauth_state');

            //                 if (error) {
            //                     reject(new Error(error));
            //                     return;
            //                 }

            //                 if (!code) {
            //                     reject(new Error('Authorization code not provided'));
            //                     return;
            //                 }

            //                 if (state !== storedState) {
            //                     reject(new Error('State mismatch. Possible CSRF attack.'));
            //                     return;
            //                 }

            //                 resolve(code);
            //             } catch (err) {
            //                 reject(err);
            //             }
            //         };

            //         window.addEventListener('message', handleMessage);
            //     });

            //     // Exchange authorization code for access token
            //     async function exchangeCodeForToken(code) {
            //         const tokenResponse = await fetch('YOUR_BACKEND_TOKEN_ENDPOINT', {
            //             method: 'POST',
            //             headers: {
            //                 'Content-Type': 'application/json',
            //             },
            //             body: JSON.stringify({code}),
            //         });

            //         if (!tokenResponse.ok) {
            //             throw new Error('Failed to exchange code for token');
            //         }

            //         return tokenResponse.json();
            //     }

            //     // Get LinkedIn user profile
            //     async function getLinkedInProfile(accessToken) {
            //         const response = await fetch('https://api.linkedin.com/v2/me', {
            //             headers: {
            //                 Authorization: `Bearer ${accessToken}`,
            //             },
            //         });

            //         if (!response.ok) {
            //             throw new Error('Failed to fetch LinkedIn profile');
            //         }

            //         return response.json();
            //     }

            //     // Exchange code for token
            //     const token = await exchangeCodeForToken(authResponse);
            //     const profile = await getLinkedInProfile(token.access_token);

            //     return {token, profile};

            // } catch (error) {
            //     console.error('LinkedIn authentication error:', error.message);
            //     throw error;
            // }
        },
        login: async (data) => invoke(ENDPOINTS.LOGIN, 'POST', data),
        logout: async () => invoke(ENDPOINTS.LOGOUT, 'DELETE'),
        getUser: async () => invoke(ENDPOINTS.GET_USER, 'GET'),
    };
}