import { defineStore } from "pinia";
import { useActionCable } from "@/services/action-cable.service";
import { CHANNELS } from "@/utils/constant";
import { computed, reactive } from "vue";

/**
 * AuthStore to manage user authentication state
 */
export const useAuthStore = defineStore("auth", () => {
  const state = reactive({
    /**
     * Current authenticated user
     * @type {Object|null}
     */
    current_user: null,
    user_type: null,
    user_profile_hashid: null,
  });

  const { onReceived, onConnected, perform, subscribe } = useActionCable(
    CHANNELS.APP
  );
  /**
   * Check if the user is logged in
   *
   * @returns {boolean} - true if user is logged in, false otherwise
   */
  const isLoggedIn = computed(() => state.current_user !== null);

  /**
   * Get the current authenticated user
   *
   * @returns {Object|null} - The current user or null if no user is logged in
   */
  const currentUser = computed(() => state.current_user);

  /**
   * Check if the user has a specific permission
   *
   * @param {string} permission - The permission to check
   * @returns {boolean} - true if user has the permission, false otherwise
   */
  const hasPermission = (permission) =>
    state.current_user?.permissions?.includes(permission);

  /**
   * Check if the user has a specific role
   *
   * @param {string} role - The role to check
   * @returns {boolean} - true if user has the role, false otherwise
   */
  const hasRole = (role) => state.current_user?.roles?.includes(role);

  /**
   * Check if the current user is an admin
   *
   * @returns {boolean} - true if user is an admin, false otherwise
   */
  const isAdmin = computed(() => !!state.current_user?.is_admin);

  /**
   * Get the name of the current authenticated user
   *
   * @returns {string|null} - The name of the current user or null if no user is logged in
   */
  const name = computed(() => state.current_user?.name);

  /**
   * Get the email of the current authenticated user
   *
   * @returns {string|null} - The email of the current user or null if no user is logged in
   */
  const email = computed(() => state.current_user?.email);

  const userType = computed(() => state.user_type);
  const userProfileHashId = computed(() => state.user_profile_hashid);

  // Action for initialising App Channel
  const initialiseChannel = async () => {
    console.log("Starting App channel initialization...");
    try {
      await subscribe();
      console.log("App channel subscribed");
      console.log("Channels initialized successfully");
    } catch (error) {
      console.log(error, "error while initialising App Channel");
    }
  };

  const setUserData = (userData) => {
    state.current_user = {
      email: userData.email,
      name: userData.user_details.name,
      roles: userData.user_details.roles,
    };
    state.user_type = userData.user_details.current_user_profile.user_type;
    state.user_profile_hashid =
      userData.user_details.current_user_profile.user_profile_hashid;
  };

  const logout = async () => {
    try {
      // Add API call to backend logout endpoint if needed
      state.current_user = null;
      state.user_type = null;
      state.user_profile_hashid = null;
    } catch (error) {
      console.error("Logout failed:", error);
      throw error;
    }
  };

  onReceived(({ body }) => {
    state.current_user = body || null;
  }, "initialize_app");

  onConnected(() => perform("initialize_app"));

  return {
    isLoggedIn,
    currentUser,
    isAdmin,
    name,
    email,
    hasRole,
    hasPermission,
    userType,
    userProfileHashId,
    setUserData,
    initialiseChannel,
    logout,
  };
});
