<template>
  <header
      class="h-16 bg-white border-b border-gray-200 flex items-center justify-between px-6 fixed top-0 left-0 right-0 z-20"
  >
    <!-- Logo -->
    <div class="flex items-center gap-2">
      <div
          class="w-8 h-8 rounded-xl border-2 border-primary flex items-center justify-center bg-primary/10 text-primary"
      >
        <span class="text-lg font-bold">P</span>
      </div>
      <h1 class="text-xl font-bold text-primary">PrioHire</h1>
    </div>

    <!-- Right Icons -->
    <div class="flex items-center gap-4">
      <!-- Notifications -->
      <div class="relative">
        <button
            @click="showNotifications = !showNotifications"
            class="relative btn btn-ghost btn-sm"
        >
          <IconBell class="w-6 h-6" stroke-width="1.75"/>
          <span
              class="absolute -top-1 -right-1 w-4 h-4 bg-error text-white text-xs rounded-full flex items-center justify-center"
          >3</span
          >
        </button>
        <!-- Notifications Dropdown -->
        <div
            v-if="showNotifications"
            class="absolute top-full right-0 mt-2 w-96 bg-base-100 rounded-xl shadow-2xl border border-base-200 z-30"
            :class="{ 'max-w-[calc(100vw-2rem)]': isMobile }"
        >
          <div class="bg-white rounded-lg shadow-lg">
            <!-- Header -->
            <div class="p-4 border-b border-base-200">
              <div class="flex justify-between items-center">
                <h2 class="text-lg font-semibold">Notifications</h2>
                <button
                    class="text-sm text-gray-500 hover:text-gray-700 transition-colors"
                >
                  Mark all as read
                </button>
              </div>
            </div>

            <!-- Notification List -->
            <div
                class="hide-scrollbar overflow-y-auto"
                style="max-height: min(400px, 60vh)"
            >
              <div
                  v-for="(notification, index) in notifications"
                  :key="notification.id"
                  class="p-4 hover:bg-base-100/50 transition-colors"
                  :class="{
                  'border-b border-base-200':
                    index !== notifications.length - 1,
                  'bg-base-100/20': !notification.read,
                }"
              >
                <div class="flex items-start gap-3">
                  <div class="flex-shrink-0">
                    <img
                        v-if="notification.user.avatar"
                        :src="notification.user.avatar"
                        :alt="notification.user.name"
                        class="w-10 h-10 rounded-full object-cover"
                    />
                    <div
                        v-else
                        class="w-10 h-10 rounded-full flex items-center justify-center text-white text-sm font-medium"
                        :class="getBackgroundColor(notification.user.name)"
                    >
                      {{ getInitials(notification.user.name) }}
                    </div>
                  </div>

                  <div class="flex-1 min-w-0">
                    <div class="flex items-start justify-between gap-2">
                      <div>
                        <div class="font-medium text-base">
                          {{ notification.user.name }}
                        </div>
                        <div class="text-sm text-gray-600 mt-0.5">
                          {{ notification.message }}
                        </div>
                        <div class="text-xs text-gray-500 mt-1">
                          {{ notification.time }}
                        </div>
                      </div>
                    </div>

                    <div class="flex items-center gap-2 mt-3">
                      <button
                          v-if="
                          notification.type === 'application' &&
                          notification.status === 'pending'
                        "
                          class="btn btn-xs btn-primary"
                      >
                        Review Application
                      </button>
                      <button
                          v-if="
                          notification.type === 'interview' &&
                          notification.status === 'scheduled'
                        "
                          class="btn btn-xs"
                      >
                        View Schedule
                      </button>
                      <button
                          v-if="
                          notification.type === 'assessment' &&
                          notification.status === 'completed'
                        "
                          class="btn btn-xs"
                      >
                        View Results
                      </button>
                      <div
                          v-if="notification.status === 'approved'"
                          class="flex items-center gap-1 text-success text-sm"
                      >
                        <IconCircleCheck class="w-4 h-4"/>
                        Approved
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Empty State -->
            <div
                v-if="notifications.length === 0"
                class="p-8 text-center text-gray-500"
            >
              <i class="bi bi-bell-slash text-3xl mb-2"></i>
              <p>No notifications yet</p>
            </div>

            <!-- Footer -->
            <div class="p-4 border-t border-base-200">
              <button
                  class="btn btn-primary w-full flex items-center justify-center gap-2"
              >
                <router-link
                    :to="{ name: ROUTE_NAMES.COMPANY.NOTIFICATIONS }"
                    class="flex items-center justify-center gap-2"
                >
                  <span>See all notifications</span>
                  <IconChevronRight class="w-4 h-4"/>
                </router-link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Profile Dropdown -->
      <div class="relative">
        <button
            @click="showProfileMenu = !showProfileMenu"
            class="flex items-center gap-2"
        >
          <img
              v-if="authStore.currentUser.avatar"
              :src="authStore.currentUser.avatar"
              alt="Profile"
              class="w-8 h-8 rounded-full"
          />
          <div
              v-else
              class="w-8 h-8 rounded-full flex items-center justify-center text-white text-sm font-medium"
              :class="getBackgroundColor(authStore.currentUser.name)"
          >
            {{ getInitials(authStore.currentUser.name) }}
          </div>
          <span class="hidden md:block font-medium">{{
              authStore.currentUser.name
            }}</span>
          <i class="bi bi-chevron-down"></i>
        </button>
        <div
            v-if="showProfileMenu"
            class="absolute top-full right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200"
        >
          <div class="p-2 space-y-1">
            <a
                v-for="item in profileMenu"
                :key="item.name"
                href="#"
                class="flex items-center gap-2 px-3 py-2 rounded-lg hover:bg-primary/10"
            >
              <component
                  :is="item.icon"
                  height="28"
                  width="28"
                  stroke-width="1.75"
              />
              {{ item.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import {useAuthStore} from "@/auth/auth.store";

const authStore = useAuthStore();
import {ref} from "vue";
import {
  IconBell,
  IconChevronRight,
  IconUser,
  IconBuilding,
  IconSettings,
  IconLogout,
  IconCircleCheck,
} from "@tabler/icons-vue";
import {ROUTE_NAMES} from "../../../utils/routes.const";

const showNotifications = ref(false);
const showProfileMenu = ref(false);

// Add sample notifications data
const notifications = ref([
  {
    id: 1,
    user: {
      name: "Albert Russell",
      role: "Frontend Developer",
    },
    type: "application",
    status: "pending",
    time: "2 hours ago",
    message: "Applied for Senior Frontend Developer position",
  },
  {
    id: 2,
    user: {
      name: "Celine Walsh",
      avatar: "https://randomuser.me/api/portraits/men/36.jpg",
      role: "UI/UX Designer",
    },
    type: "interview",
    status: "scheduled",
    time: "3 hours ago",
    message: "Accepted interview invitation for Product Designer role",
  },
  {
    id: 3,
    user: {
      name: "Joseph Keene",
      role: "Backend Developer",
    },
    type: "assessment",
    status: "completed",
    time: "5 hours ago",
    message: "Completed technical assessment for Python Developer position",
  },
  {
    id: 4,
    user: {
      name: "Chintya Smith",
      role: "Product Manager",
    },
    type: "application",
    status: "approved",
    time: "1 day ago",
    message: "Applied for Senior Product Manager position",
  },
]);

const getInitials = (name) => {
  return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase()
      .slice(0, 2);
};

// Function to generate a consistent background color based on name
const getBackgroundColor = (name) => {
  const colors = [
    "bg-blue-500",
    "bg-emerald-500",
    "bg-purple-500",
    "bg-pink-500",
    "bg-yellow-500",
    "bg-indigo-500",
    "bg-rose-500",
    "bg-teal-500",
  ];
  const charCodes = name.split("").map((char) => char.charCodeAt(0));
  const sum = charCodes.reduce((acc, curr) => acc + curr, 0);

  return colors[sum % colors.length];
};

const profileMenu = [
  {name: "Profile", icon: IconUser},
  {name: "Company", icon: IconBuilding},
  {name: "Settings", icon: IconSettings},
  {name: "Logout", icon: IconLogout},
];
</script>

<style scoped>
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}
</style>
