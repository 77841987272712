/**
 * List of languages with their native titles and language codes.
 * Each object in the list represents a language and includes:
 * - `language`: The name of the language in English.
 * - `native_title`: The name of the language in its native script.
 * - `language_code`: The ISO 639-1 code for the language.
 */
export const LANGUAGES = [
    {language: "Afrikaans", native_title: "Afrikaans", language_code: "af"},
    {language: "Albanian", native_title: "Shqip", language_code: "sq"},
    {language: "Amharic", native_title: "አማርኛ", language_code: "am"},
    {language: "Arabic", native_title: "العربية", language_code: "ar"},
    {language: "Armenian", native_title: "Հայերեն", language_code: "hy"},
    {language: "Azerbaijani", native_title: "Azərbaycan dili", language_code: "az"},
    {language: "Basque", native_title: "Euskara", language_code: "eu"},
    {language: "Belarusian", native_title: "Беларуская", language_code: "be"},
    {language: "Bengali", native_title: "বাংলা", language_code: "bn"},
    {language: "Bosnian", native_title: "Bosanski", language_code: "bs"},
    {language: "Bulgarian", native_title: "Български", language_code: "bg"},
    {language: "Catalan", native_title: "Català", language_code: "ca"},
    {language: "Chinese", native_title: "中文", language_code: "zh"},
    {language: "Croatian", native_title: "Hrvatski", language_code: "hr"},
    {language: "Czech", native_title: "Čeština", language_code: "cs"},
    {language: "Danish", native_title: "Dansk", language_code: "da"},
    {language: "Dutch", native_title: "Nederlands", language_code: "nl"},
    {language: "English", native_title: "English", language_code: "en"},
    {language: "Esperanto", native_title: "Esperanto", language_code: "eo"},
    {language: "Estonian", native_title: "Eesti", language_code: "et"},
    {language: "Finnish", native_title: "Suomi", language_code: "fi"},
    {language: "French", native_title: "Français", language_code: "fr"},
    {language: "Galician", native_title: "Galego", language_code: "gl"},
    {language: "Georgian", native_title: "ქართული", language_code: "ka"},
    {language: "German", native_title: "Deutsch", language_code: "de"},
    {language: "Greek", native_title: "Ελληνικά", language_code: "el"},
    {language: "Gujarati", native_title: "ગુજરાતી", language_code: "gu"},
    {language: "Haitian Creole", native_title: "Kreyòl ayisyen", language_code: "ht"},
    {language: "Hebrew", native_title: "עברית", language_code: "he"},
    {language: "Hindi", native_title: "हिन्दी", language_code: "hi"},
    {language: "Hungarian", native_title: "Magyar", language_code: "hu"},
    {language: "Icelandic", native_title: "Íslenska", language_code: "is"},
    {language: "Indonesian", native_title: "Bahasa Indonesia", language_code: "id"},
    {language: "Irish", native_title: "Gaeilge", language_code: "ga"},
    {language: "Italian", native_title: "Italiano", language_code: "it"},
    {language: "Japanese", native_title: "日本語", language_code: "ja"},
    {language: "Kannada", native_title: "ಕನ್ನಡ", language_code: "kn"},
    {language: "Kazakh", native_title: "Қазақ тілі", language_code: "kk"},
    {language: "Khmer", native_title: "ខ្មែរ", language_code: "km"},
    {language: "Korean", native_title: "한국어", language_code: "ko"},
    {language: "Lao", native_title: "ລາວ", language_code: "lo"},
    {language: "Latvian", native_title: "Latviešu", language_code: "lv"},
    {language: "Lithuanian", native_title: "Lietuvių", language_code: "lt"},
    {language: "Luxembourgish", native_title: "Lëtzebuergesch", language_code: "lb"},
    {language: "Malay", native_title: "Bahasa Melayu", language_code: "ms"},
    {language: "Malayalam", native_title: "മലയാളം", language_code: "ml"},
    {language: "Maltese", native_title: "Malti", language_code: "mt"},
    {language: "Marathi", native_title: "मराठी", language_code: "mr"},
    {language: "Mongolian", native_title: "Монгол хэл", language_code: "mn"},
    {language: "Nepali", native_title: "नेपाली", language_code: "ne"},
    {language: "Norwegian", native_title: "Norsk", language_code: "no"},
    {language: "Pashto", native_title: "پښتو", language_code: "ps"},
    {language: "Persian", native_title: "فارسی", language_code: "fa"},
    {language: "Polish", native_title: "Polski", language_code: "pl"},
    {language: "Portuguese", native_title: "Português", language_code: "pt"},
    {language: "Punjabi", native_title: "ਪੰਜਾਬੀ", language_code: "pa"},
    {language: "Romanian", native_title: "Română", language_code: "ro"},
    {language: "Russian", native_title: "Русский", language_code: "ru"},
    {language: "Serbian", native_title: "Српски", language_code: "sr"},
    {language: "Sinhala", native_title: "සිංහල", language_code: "si"},
    {language: "Slovak", native_title: "Slovenčina", language_code: "sk"},
    {language: "Slovenian", native_title: "Slovenščina", language_code: "sl"},
    {language: "Spanish", native_title: "Español", language_code: "es"},
    {language: "Swahili", native_title: "Kiswahili", language_code: "sw"},
    {language: "Swedish", native_title: "Svenska", language_code: "sv"},
    {language: "Tamil", native_title: "தமிழ்", language_code: "ta"},
    {language: "Telugu", native_title: "తెలుగు", language_code: "te"},
    {language: "Thai", native_title: "ไทย", language_code: "th"},
    {language: "Turkish", native_title: "Türkçe", language_code: "tr"},
    {language: "Ukrainian", native_title: "Українська", language_code: "uk"},
    {language: "Urdu", native_title: "اردو", language_code: "ur"},
    {language: "Uzbek", native_title: "O‘zbek", language_code: "uz"},
    {language: "Vietnamese", native_title: "Tiếng Việt", language_code: "vi"},
    {language: "Welsh", native_title: "Cymraeg", language_code: "cy"},
    {language: "Zulu", native_title: "isiZulu", language_code: "zu"}
];

/**
 * Array representing different levels of language proficiency.
 *
 * Each element in the array is an object containing:
 * - title: A string that provides the name of the proficiency level.
 * - value: A numerical value that represents the ranking of the proficiency level.
 *
 * The proficiency levels are:
 * - Native: Maximum proficiency, represented by the value 1.
 * - Basic: Minimal proficiency, represented by the value 2.
 * - Conversational: Intermediate proficiency, represented by the value 3.
 * - Fluent: High proficiency, represented by the value 4.
 */
export const LANGUAGE_PROFICIENCY = [
    {title: "Native", value: 1,},
    {title: "Basic", value: 2},
    {title: "Conversational", value: 3},
    {title: "Fluent", value: 4},
];

export const CHANNELS = {
    APP: "AppChannel",
    NOTIFICATION: "NotificationChannel",
    COMPANY: "CompanyChannel",
    CONSULTANT: "ConsultantChannel",
    CANDIDATE: "CandidateChannel",
    PRIOHIRE_ADMIN: "PriohireAdminChannel",
    USERSETTING: "UserSettingChannel",
    JOB: "JobChannel"

};