<template>
  <div class="min-h-screen flex flex-col bg-white">
    <TopBar />
    <div class="flex flex-1 pt-16">
      <div class="fixed left-0 top-16 bottom-0" v-if="!isMobile">
        <SideBar/>
      </div>
      <div class="fixed bottom-0 w-full z-10" v-else>
        <SideBar/>
      </div>
      <div class="flex-1" :class="{ 'ml-24': !isMobile }">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script setup>
import TopBar from "./Components/ConsultantTopbar.vue";
import SideBar from "./Components/ConsultantSidebar.vue";

import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";
const { width } = useWindowSize();
const isMobile = computed(() => width.value <= 1024);
</script>
