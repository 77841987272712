/**
 * Configuration object for prioritization settings.
 *
 * @typedef {Object} PrioConfig
 * @property {Object} notification - The notification settings.
 * @property {number} notification.duration - Duration for which notifications are displayed in milliseconds.
 */
export const PrioConfig = {
    "notification": {
        "duration": 5000,
        "position": "top-right"
    }
};