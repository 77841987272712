<template>
  <aside
      v-if="!isMobile"
      class="w-24 bg-white flex flex-col items-center py-4"
      aria-label="Sidebar navigation"
  >
    <NavigationMenu
        :links="NAVIGATION_LINKS"
        :is-active="isActive"
        :class-name="'hover:bg-gray-50 p-3 rounded-lg transition-colors duration-200'"
        :active-class-name="'bg-gray-100 text-blue-600'"
    />
  </aside>
  <nav
      v-else
      class="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 flex justify-around py-2 z-50 lg:hidden"
      aria-label="Bottom navigation"
  >
    <NavigationMenu
        :links="NAVIGATION_LINKS"
        :is-active="isActive"
        :is-mobile="true"
        :class-name="'hover:bg-gray-50 p-3 rounded-lg transition-colors duration-200'"
        :active-class-name="'bg-gray-100 text-blue-600'"
    />
  </nav>
</template>

<script setup>
import {computed} from "vue";
import {useRoute} from "vue-router";
import {useWindowSize} from "@vueuse/core";
import {
  IconHome,
  IconUser,
  IconBriefcase,
  IconHelpCircle,
  IconPencil,
  IconSettings,
} from "@tabler/icons-vue";
import {ROUTE_NAMES} from "@/utils/routes.const";
import NavigationMenu from "@/Common/NavigationMenu.vue";

const {width} = useWindowSize();
const isMobile = computed(() => width.value <= 1024);
const route = useRoute();

const NAVIGATION_LINKS = [
  {title: "Home", name: ROUTE_NAMES.COMPANY.DASHBOARD, icon: IconHome},
  {title: "Jobs", name: ROUTE_NAMES.COMPANY.JOBS, icon: IconBriefcase},
  {title: "New Job", name: ROUTE_NAMES.COMPANY.CREATE_JOB, icon: IconPencil},
  {title: "Profile", name: ROUTE_NAMES.COMPANY.PROFILE, icon: IconUser},
  {
    title: "Settings",
    name: ROUTE_NAMES.COMPANY.SETTINGS_GENERAL,
    icon: IconSettings,
  },
  {title: "Help", name: "", icon: IconHelpCircle},
];

const isActive = (name) => {
  if (!name) return false;
  if (name === ROUTE_NAMES.COMPANY.SETTINGS_GENERAL) {
    return route.name?.startsWith("company.settings") ?? false;
  }
  return route.name === name;
};
</script>
