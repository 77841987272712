import { ROUTE_NAMES } from "@/utils/routes.const";
import ConsultantLayout from "@/consultant/layouts/ConsultantLayout.vue";

/**
 * ConsultantRoutes is an array of route records which are used to define the
 * Consultant-related routes in a web application. Each route record
 * adheres to the RouteRecordRaw interface, a structure that defines
 * the properties and configuration for each route.
 *
 * Example route configurations may include paths, names, components,
 * redirection targets, guards, and other route-specific metadata.
 *
 * Typically used within a routing library such as Vue Router in a
 * Vue.js application, these routes are essential for managing Consultant
 * processes like dashboard, job postings, candidates, etc.
 *
 * @name ConsultantRoutes
 */
export const ConsultantRoutes = [
  {
    path: "/consultant",
    component: ConsultantLayout,
    children: [
      {
        name: ROUTE_NAMES.CONSULTANT.DASHBOARD,
        path: "",
        component: () =>
          import("@/consultant/pages/Dashboard/DashboardIndex.vue"),
        meta: { auth: true, title: "Dashboard" },
      },
      {
        path: "jobs",
        component: () => import("@/consultant/pages/Jobs/Jobs.vue"),
        children: [
          {
            name: ROUTE_NAMES.CONSULTANT.JOBS,
            path: "",
            component: () => import("@/consultant/pages/Jobs/JobIndex.vue"),
            meta: { auth: true, title: "Jobs" },
          },
          {
            name: ROUTE_NAMES.CONSULTANT.JOB_DETAILS,
            path: ":job_hashid",
            component: () =>
              import("@/consultant/pages/Jobs/Components/JobDetails.vue"),
            meta: { auth: true, title: "Job Details" },
            props: true,
          },
          {
            name: ROUTE_NAMES.CONSULTANT.CANDIDATES,
            path: ":job_hashid/candidates",
            component: () =>
              import("@/consultant/pages/Others/ManageCandidates.vue"),
            meta: { auth: true, title: "Candidates" },
            props: true,
          },
          {
            name: ROUTE_NAMES.CONSULTANT.CANDIDATE,
            path: ":job_hashid/candidates/:candidate_id",
            component: () =>
              import("@/consultant/pages/Others/CandidateView.vue"),
            meta: { auth: true, title: "Candidate" },
            props: true,
          },
        ],
      },
      {
        name: ROUTE_NAMES.CONSULTANT.CREATE_JOB,
        path: "jobs/create",
        component: () => import("@/consultant/pages/Jobs/Create.vue"),
        meta: { auth: true, title: "Create Job" },
      },
      {
        name: ROUTE_NAMES.CONSULTANT.PROFILE,
        path: "profile",
        component: () => import("@/consultant/pages/Profile/ProfileIndex.vue"),
        meta: { auth: true, title: "Profile" },
      },
      {
        path: "companies",
        component: () => import("@/consultant/pages/ConsultantCompanies/Company.vue"),
        children: [
          {
            name: ROUTE_NAMES.CONSULTANT.COMPANIES,
            path: "",
            component: () =>
              import("@/consultant/pages/ConsultantCompanies/CompanyIndex.vue"),
            meta: { auth: true, title: "Companies" },
          },
          {
            name: ROUTE_NAMES.CONSULTANT.COMPANY_DETAILS,
            path: ":company_hashid",
            component: () =>
              import("@/consultant/pages/ConsultantCompanies/Components/CompanyDetails.vue"),
            meta: { auth: true, title: "Company Details" },
            props: true,
          },
          {
            name: ROUTE_NAMES.CONSULTANT.COMPANY_JOBS,
            path: ":company_hashid/jobs",
            component: () =>
              import("@/consultant/pages/Jobs/JobIndex.vue"),
            meta: { auth: true, title: "Jobs" },
            props: true,
          },
          {
            name: ROUTE_NAMES.CONSULTANT.COMPANY_JOB,
            path: ":company_hashid/jobs/:job_hashid",
            component: () =>
              import("@/consultant/pages/Jobs/Components/JobDetails.vue"),
            meta: { auth: true, title: "Job Details" },
            props: true,
          },
        ],
      },
      {
        name: ROUTE_NAMES.CONSULTANT.SETTINGS,
        path: "settings",
        component: () => import("@/consultant/pages/Settings/SettingIndex.vue"),
        meta: { auth: true, title: "Settings" },
        children: [
          {
            path: "general",
            name: ROUTE_NAMES.CONSULTANT.SETTINGS_GENERAL,
            component: () =>
              import(
                "@/consultant/pages/Settings/Components/Basics/GeneralSettings.vue"
              ),
          },
          {
            path: "basic-details",
            name: ROUTE_NAMES.CONSULTANT.SETTINGS_BASIC_DETAILS,
            component: () =>
              import(
                "@/consultant/pages/Settings/Components/Basics/BasicDetails.vue"
              ),
          },
          {
            path: "email",
            name: ROUTE_NAMES.CONSULTANT.SETTINGS_EMAIL,
            component: () =>
              import(
                "@/consultant/pages/Settings/Components/Basics/EmailSettings.vue"
              ),
          },
          {
            path: "password",
            name: ROUTE_NAMES.CONSULTANT.SETTINGS_PASSWORD,
            component: () =>
              import(
                "@/consultant/pages/Settings/Components/Security/ChangePassword.vue"
              ),
          },
          {
            path: "2fa",
            name: ROUTE_NAMES.CONSULTANT.SETTINGS_2FA,
            component: () =>
              import(
                "@/consultant/pages/Settings/Components/Security/TwoFactorSettings.vue"
              ),
          },
          {
            path: "slack",
            name: ROUTE_NAMES.CONSULTANT.SETTINGS_SLACK,
            component: () =>
              import(
                "@/consultant/pages/Settings/Components/Security/SlackSettings.vue"
              ),
          },
          {
            path: "notification",
            name: ROUTE_NAMES.CONSULTANT.SETTINGS_NOTIFICATIONS,
            component: () =>
              import(
                "@/consultant/pages/Settings/Components/NotificationSettings.vue"
              ),
          },
          {
            path: "roles",
            name: ROUTE_NAMES.CONSULTANT.SETTINGS_ROLES,
            component: () =>
              import(
                "@/consultant/pages/Settings/Components/RolesSettings.vue"
              ),
          },
        ],
      },
      {
        name: ROUTE_NAMES.CONSULTANT.NOTIFICATIONS,
        path: "notifications",
        component: () =>
          import("@/consultant/pages/Notification/NotificationIndex.vue"),
        meta: { auth: true, title: "Notifications" },
      },
    ],
  },
];
