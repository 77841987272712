<template>
  <aside
    v-if="!isMobile"
    class="w-24 bg-white flex flex-col items-center py-4"
    aria-label="Sidebar navigation"
  >
    <NavigationMenu 
      :links="NAVIGATION_LINKS" 
      :is-active="isActive"
      :class-name="'hover:bg-gray-50 p-3 rounded-lg transition-colors duration-200'"
      :active-class-name="'bg-gray-100 text-blue-600'"
    />
  </aside>
  <nav
    v-else
    class="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 flex justify-around py-2 z-50 lg:hidden"
    aria-label="Bottom navigation"
  >
    <NavigationMenu
      :links="NAVIGATION_LINKS"
      :is-active="isActive"
      :is-mobile="true"
      :class-name="'hover:bg-gray-50 p-3 rounded-lg transition-colors duration-200'"
      :active-class-name="'bg-gray-100 text-blue-600'"
    />
  </nav>
</template>

<script setup>
import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";
import { useRoute } from 'vue-router';
import {ROUTE_NAMES} from "@/utils/routes.const";
import NavigationMenu from "@/Common/NavigationMenu.vue";
import {
  IconHome,
  IconUser,
  IconBuildings,
  IconBriefcase,
  IconHelpCircle,
  IconPencil,
  IconSettings
} from "@tabler/icons-vue";

const { width } = useWindowSize();
const isMobile = computed(() => width.value <= 1024);
const route = useRoute();

const NAVIGATION_LINKS = [
  {title: "Home", name: ROUTE_NAMES.CONSULTANT.DASHBOARD, icon: IconHome},
  {title: "Jobs", name: ROUTE_NAMES.CONSULTANT.JOBS, icon: IconBriefcase},
  {title: "Company", name: ROUTE_NAMES.CONSULTANT.COMPANIES, icon: IconBuildings},
  {title: "New Job", name: ROUTE_NAMES.CONSULTANT.CREATE_JOB, icon: IconPencil},
  {title: "Profile", name: ROUTE_NAMES.CONSULTANT.PROFILE, icon: IconUser},
  {title: "Settings", name: ROUTE_NAMES.CONSULTANT.SETTINGS_GENERAL, icon: IconSettings},
  {title: "Help", name: ROUTE_NAMES.CONSULTANT.SETTINGS, icon: IconHelpCircle},
];

const isActive = (name) => {
  if (!name) return false;
  if (name === ROUTE_NAMES.COMPANY.SETTINGS_GENERAL) {
    return route.name?.startsWith('company.settings') ?? false;
  }
  return route.name === name;
};
</script>

<style>
.selected {
  background-color: #f0f0f0;
}
</style>
