import { createRouter, createWebHashHistory } from "vue-router";
import { AuthRoutes } from "@/auth/auth.routes";
import { CompanyRoutes } from "@/company/company.routes";
import { ConsultantRoutes } from "@/consultant/consultant.routes";
import { CandidateRoutes } from "@/candidate/candidate.routes";
import { useTitle } from "@vueuse/core";
import { useAuthStore } from "@/auth/auth.store";
import { ROUTE_NAMES } from "@/utils/routes.const";
import { useToastStore } from "@/services/toast.service";
import { useApi } from "@/services/api.service";

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
  routes: [
    { name: "home", path: "/" },
    {
      name: "sample.theme",
      path: "/theme",
      component: import("@/utils/SampleTheme.vue"),
    },
    ...AuthRoutes,
    ...CompanyRoutes,
    ...ConsultantRoutes,
    ...CandidateRoutes,
  ],
});

const assignTitle = (to, is_admin = false) => {
  const title = useTitle("Home", {
    titleTemplate: `%s |${is_admin ? " (Admin)" : ""} PrioHire`,
  });
  title.value = to.meta.title || title.value;
};

// Global Before Guards
router.beforeEach(async (to, from, next) => {
  const { isLoggedIn, isAdmin, hasRole, hasPermission, userType } =
    useAuthStore();
  const { getUser } = useApi();
  const toast = useToastStore();

  // Define protected paths and their corresponding user types
  const protectedPaths = {
    'candidate': 'CANDIDATE',
    'consultant': 'CONSULTANT',
    'company': 'COMPANY'
  };

  // Check if current path starts with any protected path
  if (isLoggedIn && userType) {
    // Extract the first segment of the route name (e.g., 'company' from 'company.dashboard')
    const routePrefix = to.name?.split('.')[0];
    
    // Check if this is a protected route
    if (routePrefix && protectedPaths[routePrefix]) {
      const requiredType = protectedPaths[routePrefix];
      if (userType !== requiredType) {
        toast.warning(
          `You don't have access to ${requiredType.toLowerCase()} pages as a ${userType.toLowerCase()}.`,
          "Access Denied"
        );
        return next(false);
      }
    }
  }

  // Special handling for home route
  if (to?.name === ROUTE_NAMES.HOME) {
    if (isLoggedIn) {
      // Redirect based on user type if already logged in
      const userTypeRoute = {
        candidate: ROUTE_NAMES.CANDIDATE.DASHBOARD,
        consultant: ROUTE_NAMES.CONSULTANT.DASHBOARD,
        company: ROUTE_NAMES.COMPANY.DASHBOARD,
      }[userType?.toLowerCase()];

      return next({ name: userTypeRoute || ROUTE_NAMES.HOME });
    }
    return next();
  }

  // Handle routes that do not require authentication
  if (to.meta.auth === false) {
    if (isLoggedIn) {
      return next({ name: ROUTE_NAMES.HOME });
    }
    assignTitle(to);
    return next();
  }

  // User must be authenticated
  if (!isLoggedIn) {
    // Try to get user data if not logged in (handles page reload)
    try {
      const { data, status } = await getUser();
      if (status === 200) {
        const authStore = useAuthStore();
        authStore.setUserData(data);

        // If trying to access home, redirect based on user type
        if (to.name === ROUTE_NAMES.HOME) {
          const userTypeRoute = {
            candidate: ROUTE_NAMES.CANDIDATE.DASHBOARD,
            consultant: ROUTE_NAMES.CONSULTANT.DASHBOARD,
            company: ROUTE_NAMES.COMPANY.DASHBOARD,
          }[authStore.userType?.toLowerCase()];

          return next({ name: userTypeRoute || ROUTE_NAMES.HOME });
        }
      } else {
        return next({ name: ROUTE_NAMES.AUTH.LOGIN });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return next({ name: ROUTE_NAMES.AUTH.LOGIN });
    }
  }

  // Check authorization based on roles and permissions
  const hasRequiredRoles = Array.isArray(to.meta.roles)
    ? to.meta.roles.some((role) => hasRole(role))
    : true;

  const hasRequiredPermissions = Array.isArray(to.meta.permissions)
    ? to.meta.permissions.some((permission) => hasPermission(permission))
    : true;

  if (!hasRequiredRoles || !hasRequiredPermissions) {
    toast.warning(
      "You do not have sufficient authorization to access this page.",
      "Authorization Warning"
    );
    return next(false);
  }

  // Check for admin access if required
  if (to.meta.admin && !isAdmin) {
    toast.warning(
      "You do not have sufficient permissions to access this page.",
      "Authorization Warning"
    );
    return next(false);
  }

  // User is authorized; proceed to the route
  assignTitle(to, isAdmin);
  next();
});

// Global Resolve Guards
router.beforeResolve(async (to) => {
  if (to.meta.requiresCamera) {
    try {
      // await askForCameraPermission();
    } catch (error) {
      if (error instanceof NotAllowedError) {
        // ... handle the error and then cancel the navigation
        return false;
      } else {
        // unexpected error, cancel the navigation and pass the error to the global handler
        throw error;
      }
    }
  }
});

// Route Error Handling
router.onError((handler) => {
  console.error(handler);
});

export default router;