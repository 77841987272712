import { ROUTE_NAMES } from "@/utils/routes.const";
import CandidateLayout from "@/candidate/layouts/CandidateLayout.vue";

/**
 * CandidateRoutes is an array of route records which are used to define the
 * candidate-related routes in a web application. Each route record
 * adheres to the RouteRecordRaw interface, a structure that defines
 * the properties and configuration for each route.
 *
 * Example route configurations may include paths, names, components,
 * redirection targets, guards, and other route-specific metadata.
 *
 * Typically used within a routing library such as Vue Router in a
 * Vue.js application, these routes are essential for managing candidate
 * processes like dashboard, job postings, candidates, etc.
 *
 * @name CandidateRoutes
 */
export const CandidateRoutes = [
  {
    path: "/candidate",
    component: CandidateLayout,
    children: [
      {
        name: ROUTE_NAMES.CANDIDATE.DASHBOARD,
        path: "",
        component: () =>
          import("@/candidate/pages/Dashboard/DashboardIndex.vue"),
        meta: { auth: true, title: "Dashboard" },
      },
      {
        name: ROUTE_NAMES.CANDIDATE.JOBS,
        path: "jobs",
        component: () => import("@/candidate/pages/Jobs/JobIndex.vue"),
        meta: { auth: true, title: "Jobs" },
      },
      {
        name: ROUTE_NAMES.CANDIDATE.PROFILE,
        path: "profile",
        component: () =>
          import("@/candidate/pages/Profile/CandidateProfile.vue"),
        meta: { auth: true, title: "Profile" },
      },
      {
        name: ROUTE_NAMES.CANDIDATE.WORK_HISTORY,
        path: "workspace",
        component: () =>
          import("@/candidate/pages/Workspace/WorkspaceIndex.vue"),
        meta: { auth: true, title: "Workspace" },
      },
      {
        name: ROUTE_NAMES.CANDIDATE.SETTINGS,
        path: "settings",
        component: () => import("@/candidate/pages/Settings/SettingIndex.vue"),
        meta: { auth: true, title: "Settings" },
        redirect: { name: ROUTE_NAMES.CANDIDATE.SETTINGS_GENERAL },
        children: [
          {
            path: "general",
            name: ROUTE_NAMES.CANDIDATE.SETTINGS_GENERAL,
            component: () =>
              import(
                "@/candidate/pages/Settings/Components/Basics/GeneralSettings.vue"
              ),
          },
          {
            path: "basic-details",
            name: ROUTE_NAMES.CANDIDATE.SETTINGS_BASIC_DETAILS,
            component: () =>
              import(
                "@/candidate/pages/Settings/Components/Basics/BasicDetails.vue"
              ),
          },
          {
            path: "email",
            name: ROUTE_NAMES.CANDIDATE.SETTINGS_EMAIL,
            component: () =>
              import(
                "@/candidate/pages/Settings/Components/Basics/EmailSettings.vue"
              ),
          },
          {
            path: "password",
            name: ROUTE_NAMES.CANDIDATE.SETTINGS_PASSWORD,
            component: () =>
              import(
                "@/candidate/pages/Settings/Components/Security/ChangePassword.vue"
              ),
          },
        //   {
        //     path: "2fa",
        //     name: ROUTE_NAMES.CANDIDATE.SETTINGS_2FA,
        //     component: () =>
        //       import("@/candidate/pages/Settings/Components/Security/TwoFactorAuthentication.vue"),
        //   },
          {
            path: "slack",
            name: ROUTE_NAMES.CANDIDATE.SETTINGS_SLACK,
            component: () =>
              import(
                "@/candidate/pages/Settings/Components/Security/SlackSettings.vue"
              ),
          },
          {
            path: "notification",
            name: ROUTE_NAMES.CANDIDATE.SETTINGS_NOTIFICATIONS,
            component: () =>
              import(
                "@/candidate/pages/Settings/Components/NotificationSettings.vue"
              ),
          },
        ],
      },
      {
        name: ROUTE_NAMES.CANDIDATE.NOTIFICATIONS,
        path: "notifications",
        component: () =>
          import("@/candidate/pages/Notification/NotificationIndex.vue"),
        meta: { auth: true, title: "Notifications" },
      },
    ],
  },
];
