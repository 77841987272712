import {createConsumer, getConfig} from "@rails/actioncable";
import {defineStore} from "pinia";
import {v4 as uuid} from "uuid";
import {useEventListener} from "@vueuse/core";
import {useToastStore} from "@/services/toast.service";
import {reactive} from "vue";

/**
 * Store definition for managing ActionCable connections and subscriptions.
 */
const useActionCableStore = defineStore('x-action-cable', {
    state: () => ({
        _options: {},
        _cable: null,
        _subscriptions: {},
        _listeners: {}
    }),
    getters: {
        /**
         * Checks if the specified channel is connected.
         *
         * @param {Object} state - The current state of the store.
         * @return {Function} - A function that accepts a channel name and returns a boolean indicating connection status.
         */
        isConnected: (state) => (channelName) => {
            return state._subscriptions[channelName]?.status === "connected" || false;
        },
    },
    actions: {
        /**
         * Opens a connection using the provided options. If options are supplied,
         * it sets them. If no connection exists, it creates one using the provided
         * URL from the configuration and dynamically determines the user's timezone.
         * Additionally, it sets up an event listener to handle actions before the window unloads.
         *
         * @param {Object} options - Configuration options for the connection.
         * @return {void} This method does not return a value.
         */
        open(options) {
            if (options) {
                this._options = options;
            }
            if (!this._cable) {
                let timezone;
                if (typeof Intl !== 'undefined' && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions) {
                    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                }
                this._cable = createConsumer(getConfig("url") + "?connection_id=" + uuid() + "&timezone=" + encodeURIComponent(timezone));

                useEventListener(window, 'beforeunload', e => {
                    this.unsubscribeAll();
                    if (this._options.debug) {
                        console.log('📥 ActionCable', 'UnsubscribeAll');
                    }
                });
            }
        },

        /**
         * Helper function to validate if a channel is subscribed.
         *
         * @param {string} channelName - The name of the channel to validate.
         * @return {boolean} - True if the channel is subscribed, false otherwise.
         */
        _validateSubscription(channelName) {
            if (!this._subscriptions[channelName]) {
                console.error(`Channel '${channelName}' is not subscribed.`);
                return false;
            }
            return true;
        },

        /**
         * Performs an action on a specific channel.
         *
         * @param {string} channel - The name of the channel.
         * @param {string} action - The action to perform.
         * @param {Object} [data={}] - The data to send with the action.
         */
        perform(channel, action, data = {}) {
            if (!this._validateSubscription(channel)) return;
            if (this._options.debug) {
                console.log('📥 ActionCable', channel, 'perform', action, data);
            }
            this._subscriptions[channel].ref.perform(action, data);
        },

        /**
         * Subscribes to a channel, managing the subscription lifecycle and event listeners.
         *
         * @param {Object|string} subscription - The subscription details or the channel name as a string.
         * @param {string|null} [name=null] - Optional name for the subscription channel.
         * @return {void}
         */
        subscribe(subscription, name = null) {
            this.open();
            if (typeof subscription === "string") {
                subscription = {channel: subscription};
            }
            const channelName = name || subscription.channel;

            if (this._subscriptions[channelName]) {
                console.warn(`Channel '${channelName}' already exists, resubscribing.`);
                this.unsubscribe(channelName); // Resubscribe if already exists
            }

            this._subscriptions[channelName] = {
                status: "",
                ref: null
            };

            const fire = (action, data) => {
                this._listeners[channelName]?.[action]?.forEach((callback) => {
                    if (typeof callback === "function") {
                        callback(data)
                    } else if (typeof callback === "object") {
                        if (callback.event === data.event) {
                            callback.callback(data);
                        }
                    }
                });
            };

            this._subscriptions[channelName].ref = this._cable.subscriptions.create(subscription, {
                connected: () => {
                    this._subscriptions[channelName].status = "connected";
                    if (this._options.debug) {
                        console.log('📥 ActionCable', channelName, 'on-connected');
                    }
                    fire("connected");
                },
                disconnected: () => {
                    this._subscriptions[channelName].status = "disconnected";
                    if (this._options.debug) {
                        console.log('📥 ActionCable', channelName, 'on-disconnected');
                    }
                    fire("disconnected");
                },
                rejected: () => {
                    this._subscriptions[channelName].status = "rejected";
                    if (this._options.debug) {
                        console.log('📥 ActionCable', channelName, 'on-rejected');
                    }
                    fire("rejected");
                },
                received: (data) => {
                    if (this._options.debug) {
                        console.log('📥 ActionCable', channelName, 'on-received', data.event, data.body, data.params, data.status);
                    }
                    if (data.event === "notification") {
                        if (!data.body) {
                            const toast = useToastStore();
                            return toast.notification(data.body.message, data.body.title, data.body.type);
                        }
                    }
                    fire("received", data);
                }
            });
            if (this._options.debug) {
                console.log('📥 ActionCable', 'subscribe', channelName, subscription);
            }
        },

        /**
         * Unsubscribes from a channel.
         *
         * @param {string} channelName - The name of the channel to unsubscribe from.
         */
        unsubscribe(channelName) {
            if (!this._validateSubscription(channelName)) return;
            this._subscriptions[channelName].ref.unsubscribe();
            delete this._listeners[channelName];
            delete this._subscriptions[channelName];
        },

        /**
         * Unsubscribes from all channels by iterating through the existing subscriptions
         * and invoking the unsubscribe method on each channel.
         *
         * @return {void}
         */
        unsubscribeAll() {
            for (const channelName in this._subscriptions) {
                this.unsubscribe(channelName);
            }
        },

        /**
         * Safely registers a callback for a specific event on a given channel.
         *
         * @param {string} channelName - The name of the channel.
         * @param {string} eventType - The type of event to listen for.
         * @param {Function} callback - The callback to execute when the event occurs.
         * @return {boolean} - True if the listener was added successfully, false otherwise.
         */
        _addListener(channelName, eventType, callback) {
            // Initialize the event type array if it doesn't exist
            if (!this._listeners[channelName]) {
                this._listeners[channelName] = {};
            }
            if (!this._listeners[channelName][eventType]) {
                this._listeners[channelName][eventType] = [];
            }
            this._listeners[channelName][eventType].push(callback);
            return true;
        },

        // Callback registration helpers for different events
        /**
         * Registers a callback for the "connected" event on a given channel.
         *
         * @param {string} channelName - The name of the channel.
         * @param {Function} callback - The callback to execute when the channel is connected.
         * @return {boolean} - True if the listener was added successfully, false otherwise.
         */
        onConnected(channelName, callback) {
            return this._addListener(channelName, 'connected', callback);
        },
        /**
         * Registers a callback for the "disconnected" event on a given channel.
         *
         * @param {string} channelName - The name of the channel.
         * @param {Function} callback - The callback to execute when the channel is disconnected.
         * @return {boolean} - True if the listener was added successfully, false otherwise.
         */
        onDisconnected(channelName, callback) {
            return this._addListener(channelName, 'disconnected', callback);
        },
        /**
         * Registers a callback for the "rejected" event on a given channel.
         *
         * @param {string} channelName - The name of the channel.
         * @param {Function} callback - The callback to execute when the channel is rejected.
         * @return {boolean} - True if the listener was added successfully, false otherwise.
         */
        onRejected(channelName, callback) {
            return this._addListener(channelName, 'rejected', callback);
        },
        /**
         * Registers a callback function to be executed when an event is received on the specified channel.
         *
         * @param {string} channelName - The name of the channel to listen for events on.
         * @param {function} callback - The function to be called when the event is received.
         * @param {string} [event] - An optional event name to listen for. If omitted, the callback will be called for any event.
         * @return {boolean} True if the listener was successfully added, otherwise false.
         */
        onReceived(channelName, callback, event) {
            return this._addListener(channelName, 'received', event ? {event, callback} : callback);
        },
    },
});

/**
 * Initializes the ActionCable connection with optional settings.
 *
 * @param {Object} [options={}] - Optional configuration for initializing ActionCable.
 * @param {boolean} [options.debug=false] - Flag to enable or disable debugging.
 */
export const initActionCable = (options = {}) => {
    options = {debug: false, ...options}
    const store = useActionCableStore();
    store.open(options); // Open the ActionCable connection if necessary
};

/**
 * Function to access the ActionCable methods.
 *
 * @param {string} channel - The name of the channel.
 * @return {Object} - An object containing ActionCable methods.
 * @throws {Error} - If the 'channel' parameter is missing.
 */
export const useActionCable = (channel) => {
    if (typeof channel === "undefined") {
        throw new Error("Missing 'channel' parameter!");
    }
    const store = useActionCableStore();
    const open = store.open;

    /**
     * Performs an action on the specified channel.
     *
     * @param {string} action - The action to perform.
     * @param {Object|null|undefined} data - The data to send with the action.
     */
    const perform = (action, data = {}) => store.perform(channel, action, data);

    /**
     * Subscribes to a channel or passes a subscription object to the store.
     *
     * @param {(string|Object|null)} [subscription=null] - The subscription to add. If null, it subscribes to the default channel.
     *                                                      If a string is provided, it is treated as the channel name.
     *                                                      If an object, it contains subscription details.
     */
    const subscribe = (subscription = null) => {
        if (subscription === null) {
            store.subscribe({channel});
        } else if (typeof subscription === "string") {
            store.subscribe({channel: subscription});
        } else if (typeof subscription === "object") {
            if (typeof subscription.channel === "string") {
                store.subscribe(subscription, channel);
            } else {
                store.subscribe({channel, ...subscription});
            }
        }
    };

    /**
     * Unsubscribes from the specified channel.
     */
    const unsubscribe = () => store.unsubscribe(channel);

    /**
     * Checks if the specified channel is connected.
     *
     * @return {boolean} - True if the channel is connected, false otherwise.
     */
    const isConnected = () => store.isConnected(channel);

    /**
     * Registers a callback for the "connected" event on the specified channel.
     *
     * @param {Function} cb - The callback to execute when the channel is connected.
     */
    const onConnected = (cb) => store.onConnected(channel, cb);

    /**
     * Registers a callback for the "disconnected" event on the specified channel.
     *
     * @param {Function} cb - The callback to execute when the channel is disconnected.
     */
    const onDisconnected = (cb) => store.onDisconnected(channel, cb);

    /**
     * Registers a callback for the "rejected" event on the specified channel.
     *
     * @param {Function} cb - The callback to execute when the channel is rejected.
     */
    const onRejected = (cb) => store.onRejected(channel, cb);

    /**
     * Registers a callback function to be executed when a specific event is received.
     *
     * @function
     * @param {function} cb - The callback function to execute when the event is received.
     * @param {string} event - The name of the event to listen for.
     */
    const onReceived = (cb, event) => store.onReceived(channel, cb, event);

    return {
        open,
        perform,
        subscribe,
        unsubscribe,
        isConnected,
        onConnected,
        onDisconnected,
        onRejected,
        onReceived
    };
};

/**
 * Hook to use invokable ActionCable methods with reactivity.
 *
 * @param {string} channel - The name of the channel.
 * @return {Object} - An object containing reactive data and invokable method.
 */
export const useActionCableInvokable = (channel) => {
    const cable = useActionCable(channel);

    const data = reactive({
        isSubmitting: false,
        errors: null,
        result: null,
        status: null,
        request_id: null
    });

    /**
     * Invokes an action with payload on the specified channel and handles reactivity.
     *
     * @param {string} action - The action to invoke.
     * @param {Object} [payload={}] - The payload to send with the action.
     * @return {Promise} - A Promise that resolves with the result of the action.
     */
    const invoke = async (action, payload = {}) => {
        try {
            data.isSubmitting = true;
            data.error = null;
            data.status = null;
            payload._request_id = data.request_id = uuid();

            cable.perform(action, payload);

            return new Promise((resolve, reject) => {
                cable.onReceived(({event, body, status, params}) => {
                    if (params._request_id === data.request_id) {
                        if (action === event) {
                            data.result = body;
                            data.errors = null;
                            resolve({event, body, status, params});
                        }
                        if (event === "validation_error") {
                            data.errors = body;
                            data.result = null;
                            reject({event, body, status, params});
                        }
                        data.isSubmitting = false;
                    }
                });
            });
        } catch (err) {
            console.error(err);
            data.isSubmitting = false;
            data.result = null;
            data.status = null;
            data.errors = {invoke: err.message};
            return Promise.reject(err);
        }
    };

    return {
        isSubmitting: data.isSubmitting,
        result: data.result,
        errors: data.errors,
        status: data.status,
        invoke,
        cable,
    };
};