import {v4 as uuid} from "uuid";

const base_url = "http://localhost:3000";
export const initiateLinkedInLogin = () => { // or call the pages_controller.rb
    const clientId = import.meta.env.LINKEDIN_CLIENT_ID;
    const redirectUri = encodeURIComponent(base_url + '/auth/linkedin/callback');
    const scope = encodeURIComponent('openid profile email');
    const state = uuid();

    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&prompt=login`;
};

export const initiateGoogleLogin = () => {
    const base_url = "http://localhost:3000";
    const clientId = import.meta.env.GOOGLE_CLIENT_ID;
    const redirectUri = encodeURIComponent(base_url + '/auth/google/callback');
    const scope = encodeURIComponent('email profile');
    const state = uuid();

    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&prompt=select_account`;
};