import {acceptHMRUpdate, defineStore} from "pinia";
import {PrioConfig} from "@/utils/prio.config";
import {ref} from 'vue';
import {v4 as uuid} from 'uuid';
import {IconAlertTriangle, IconCircleCheck, IconInfoCircle, IconSquareRoundedX} from "@tabler/icons-vue";

const IconMap = {
    info: IconInfoCircle,
    success: IconCircleCheck,
    warning: IconAlertTriangle,
    error: IconSquareRoundedX,
};

export const useToastStore = defineStore("x-toast", () => {
    const toasts = ref([]);

    const createToast = (toast) => {
        const id = uuid();

        toast.type = toast.type || 'info';
        toast.duration = toast.duration || PrioConfig.notification.duration;
        toast.position = toast.position || PrioConfig.notification.position;

        toasts.value.push({...toast, component: IconMap[toast.type], id});

        if (toast.duration > 0) {
            setTimeout(() => {
                removeToast(id);
            }, toast.duration);
        }
    };

    const removeToast = (id) => {
        const index = toasts.value.findIndex(toast => toast.id === id);
        if (index > -1) {
            toasts.value.splice(index, 1);
        }
    };

    const success = (message, title, duration, position) => {
        createToast({type: 'success', title, message, duration, position});
    };

    const error = (message, title, duration, position) => {
        createToast({type: 'error', title, message, duration, position});
    };

    const info = (message, title, duration, position) => {
        createToast({type: 'info', title, message, duration, position});
    };

    const warning = (message, title, duration, position) => {
        createToast({type: 'warning', title, message, duration, position});
    };

    const notification = (message, title, type, duration, position) => {
        createToast({type, title, message, duration, position});
    };

    return {
        toasts,
        createToast,
        removeToast,
        success,
        error,
        info,
        warning,
        notification,
    };
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useToastStore, import.meta.hot))
}